




















































import './scss/TheAuth.scss';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { userNamespace } from '@store/modules/user';
import { UserActionTypes } from '@store/modules/user/Types';
import { makeFocusInput } from '@utility/makeFocusInput';

@Component({
    name: 'SetNewPassword',

    components: {
        BaseAuth: () => import('@pages/the-auth/TheBaseAuth.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
    },
})
export default class SetNewPassword extends Vue {
    $refs!: {
        mainForm: HTMLElement,
    }

    @userNamespace.Action(UserActionTypes.A_RESET_PASSWORD) resetPasswordMethod!: (payload) => Promise<void>;

    fieldLogin: string = '';
    error: string = '';
    realTimeValidation: boolean = false;
    errorsText: {[key: string]: string} = {
        empty: 'Не заполнено обязательное поле',
        emailFormat: 'Неверный формат E-mail',
    }
    informationPopupShow: boolean = false;
    durationShowingNotificationPopup: number = 3000;
    informationPopupText: string = '';
    realtimeValidation: boolean = false;
    btnDisabled: boolean = false;
    backKey: string | null = '';
    minLengthPass: number = 7;

    fields = {
        passwordNew: '',
        passwordNewConfirm: '',
    }

    errors = {
        passwordNew: false,
        passwordNewConfirm: false,
        empty: false,
        short: false,
        isNoLetterInPassword: false,
        isNoNumberInPassword: false,
        isNoCharacterInPassword: false,
    } as { [key: string]: boolean };

    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.backKey = urlParams.get('id');

        setTimeout(() => {
            makeFocusInput(this.$refs.mainForm);
        }, 100);
    }

    get passwordRulesText(): string {
        return `Пароль должен:
            быть длиной не менее 7 символов;
            содержать заглавную (прописную) или строчную буквы,
            цифру,
            и спецсимвол _~!?@#$%&^\`"\'*+-=<>,.:;|/\\{}()[]`;
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get errorText() {
        if (this.errorExists) {
            if (!this.fields.passwordNew.length || !this.fields.passwordNewConfirm.length) {
                return 'Вы не заполнили одно или несколько обязательных полей';
            }
            if (this.fields.passwordNew !== this.fields.passwordNewConfirm) {
                return 'Введенные пароли отличаются';
            }
            if (this.errors.short) {
                return `Длина пароля должна быть не менее ${this.minLengthPass} символов`;
            }
            if (this.errors.isNoLetterInPassword) {
                return 'Введите прописную (заглавную) или строчную буквы';
            }
            if (this.errors.isNoNumberInPassword) {
                return 'Введите цифру';
            }
            if (this.errors.isNoCharacterInPassword) {
                return 'Введите спецсимвол:  _~!?@#$%&^`"\'*+-=<>,.:;|/\\{}()[]';
            }
        }
        return '';
    }

    clickSubmit() {
        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;
            this.resetPasswordMethod({
                id: this.backKey,
                newPassword: this.fields.passwordNew,
            }).then(() => {
                this.onSuccessChangePassword();
            }).finally(() => {
                this.btnDisabled = false;
            });
        } else {
            this.realtimeValidation = true;
        }
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    validateFields() {
        const lettersRegex = /[a-zа-яё]/ig;
        const numbersRegex = /[0-9]/ig;
        const charactersRegex = /[_~!?@#$%&^`"'*+\-=<>,.:;|/\\{}()\[\]]/ig;

        this.errors.empty = Object.values(this.fields).some((item) => !item.length);
        this.errors.passwordNew = this.fields.passwordNew.length != 0
            && (this.fields.passwordNew !== this.fields.passwordNewConfirm);
        this.errors.passwordNewConfirm = this.fields.passwordNewConfirm.length != 0
            && (this.fields.passwordNew !== this.fields.passwordNewConfirm);
        this.errors.short = (this.fields.passwordNew.length < this.minLengthPass)
            || (this.fields.passwordNewConfirm.length < this.minLengthPass);
        this.errors.isNoLetterInPassword = !lettersRegex.test(this.fields.passwordNew)
            && !lettersRegex.test(this.fields.passwordNewConfirm);
        this.errors.isNoNumberInPassword = !numbersRegex.test(this.fields.passwordNew)
            && !numbersRegex.test(this.fields.passwordNewConfirm);
        this.errors.isNoCharacterInPassword = !charactersRegex.test(this.fields.passwordNew)
            && !charactersRegex.test(this.fields.passwordNewConfirm);
    }

    resetFields() {
        this.fields = {
            passwordNew: '',
            passwordNewConfirm: '',
        }
        this.errors = {
            passwordNew: false,
            passwordNewConfirm: false,
            empty: false,
            short: false,
            isNoLetterInPassword: false,
            isNoNumberInPassword: false,
            isNoCharacterInPassword: false,
        }
        this.realtimeValidation = false;
    }

    onSuccessChangePassword() {
        this.showInformationPopup('Пароль был успешно изменен');
        this.resetFields();
        this.$router.push({ path: '/auth' });
    }

    showInformationPopup(text) {
        this.informationPopupText = text;
        this.informationPopupShow = true;
        setTimeout(() => {
            this.informationPopupText = '';
            this.informationPopupShow = false;
        }, this.durationShowingNotificationPopup);
    }
}
